import { ref, watch } from '@vue/composition-api'
import { isNil, omitBy } from 'lodash-es'
import cloneDeep from 'lodash/cloneDeep'

import { apiSupportRequest } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'

import useToast from '@useToast'

export default function useListHandle() {
  const { toastError, toastSuccess } = useToast()
  const today = new Date()

  const filterBlank = {
    searchText: null,
    fromDate: convertISODateTime(today).dateFilter,
    toDate: convertISODateTime(today).dateFilter,
    airlineCode: null,
    page: 1,
    size: 10,
    sortBy: 'createdAt',
    sortDirection: 'DESC',
    status: null,
  }
  // Loading
  const loading = ref(true)

  const filter = ref(cloneDeep(filterBlank))
  const items = ref([])
  const totalItem = ref(0)

  const columns = ref([
    { label: 'stt', key: 'stt' },
    { label: 'createdBy', key: 'createdBy' },
    { label: 'pnr', key: 'pnr' },
    { label: 'flight', key: 'flight' },
    { label: 'content', key: 'content' },
    { label: 'note', key: 'note' },
    { label: 'phone', key: 'phone' },
    { label: 'createdAt', key: 'createdAt', sortable: true },
    { label: 'status', key: 'status', sortable: true },
    {
      label: 'actions',
      key: 'actions',
      stickyColumn: true,
      class: 'position-right-0',
    },
  ])

  async function fetchItem(isClearFilter) {
    if (isClearFilter) {
      filter.value = cloneDeep(filterBlank)
    }
    if (filter.value.status === 'ALL') {
      delete filter.value.status
    }
    try {
      loading.value = true
      filter.value.sortBy = filter.value.sortBy || filterBlank.sortBy
      filter.value.sortDirection = filter.value.sortDirection || filterBlank.sortDirection
      const params = omitBy(
        filter.value,
        value => isNil(value) || value === '',
      )
      const { data } = await apiSupportRequest.findAll(params)
      items.value = data.items
      totalItem.value = data.count
      loading.value = false
    } catch (error) {
      toastError(error)
    }
  }

  async function changeStatus(id, status, note) {
    try {
      await apiSupportRequest.update(id, { status, note })
      toastSuccess('supportReq.updateSuccess')
    } catch (error) {
      toastError(error)
      throw error
    }
  }
  async function getLogsById(id) {
    try {
      const { data } = await apiSupportRequest.getLogsById(id)
      return data
    } catch (error) {
      toastError(error)
      throw error
    }
  }
  watch(
    [filter],
    () => {
      fetchItem()
    },
    { deep: true },
  )

  return {
    // Loading
    loading,
    filter,
    items,
    totalItem,
    columns,
    // Functions
    fetchItem,
    changeStatus,
    getLogsById,
  }
}
